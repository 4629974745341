import React from 'react';
import { Link } from 'gatsby';

import '../styles/components/redLogo.scss';

import smallLogo from '../images/logo/red/32x32.svg';
import bigLogo from '../images/logo/red/64x64.svg';
import useLanguage from "../hooks/useLanguage";

const RedLogo = () => {
    const [currentLanguage] = useLanguage();
    const prefixUrl = (currentLanguage || 'ru') === 'en' ? 'en' : '';

    return (
        <Link to={`/${prefixUrl}`} className="RedLogo">
            <img alt="logo" src={smallLogo} className="RedLogo-Small" />
            <img alt="logo" src={bigLogo} className="RedLogo-Big" />
        </Link>
    )
};

export default RedLogo;
